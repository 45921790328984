
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "FinancialSkillsModules",
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Course Lists", ["Financial Skills"]);
    });

    return {};
  },
  data() {
    const store = useStore();

    return {
      store,
      courseLists: [],
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_COURSE_LISTS).then(() => {
        this.courseLists = this.store.getters.getCourseListsData;
      });
    },
  },
  async mounted() {
    this.init();
  },
});
